import { isBoolean, isNumber, isObject } from "lodash";
import { addMonths, differenceInCalendarDays, format, subMonths } from "date-fns";
import { getSslDetails } from "./api";

export const userNameFormatter = (userObj, includeEmail = false) => {
  return `${userObj.first_name} ${userObj.last_name ?? ''}${includeEmail ? " (" + userObj.email + ")" : ""}`;
};

export const getSslStatus = async (secureDomain, sslDetails = null) => {
  if (secureDomain) {
    let responseSSL = await getSslDetails(secureDomain);
    sslDetails = await responseSSL?.json();
  }
  return { sslDetails };
};

export const convertSlugToTitle = (slug) => {
  let words = slug.replace(/([A-Z])/g, " $1");
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export const getSecureDomain = domain => {
  try {
    const url = new URL(domain);
    if (url.protocol === 'https:') {
      return url.host;
    }
    return false;
  } catch (e) {
    return false;
  }
}

/**
 * Convert minutes to milliseconds
 * @param time
 * @returns {number}
 */
export const convertMinsToMs = time => {
  return time * 60 * 1000;
};

export const convertMsToMins = time => {
  return time / 60000;
};

export const getLastUpdatedDate = (notes, formatDate = true) => {
  const updateNotes = notes.filter(note => note.is_update === true).sort((a, b) => a.date_created - b.date_created);
  if (formatDate) {
    return updateNotes[0]?.date_created ? format(new Date(updateNotes[0]?.date_created), "dd/MM/Y") : "-";
  }
  return updateNotes[0]?.date_created ? new Date(updateNotes[0]?.date_created) : "-";
};

export const getLatestUpdateDate = (notes = [], formatDate = true) => {
  if (notes && notes.length > 0) {
    const lastUpdatedDate = new Date(notes.reduce((a, b) => {
      return new Date(a.date_created) > new Date(b.date_created) ? a : b;
    }, {}).date_created);
    if (formatDate) {
      return format(lastUpdatedDate, "dd/MM/Y");
    }
    return lastUpdatedDate;
  }
  return "-";
};

export const getUpdatesRequired = (notes, updateFrequency, debug = false) => {
  let updateColour = "red";
  let difference = 0;
  let hasUpdateFrequency = ["monthly", "quarterly"].includes(updateFrequency);
  if (hasUpdateFrequency && notes.length === 0) {
    updateColour = "red";
  }
  const latestUpdatedDate = getLatestUpdateDate(notes, false);
  if (hasUpdateFrequency && !latestUpdatedDate) {
    updateColour = "red";
  }
  if (debug) {
    console.log(differenceInCalendarDays(new Date(), latestUpdatedDate));
  }
  switch (updateFrequency) {
    case "adhoc":
    case "none":
      updateColour = "gray";
      break;
    case "monthly":
      difference = differenceInCalendarDays(new Date(), latestUpdatedDate);
      if (difference < 24) {
        updateColour = "green";
      } else if (difference >= 24 && difference <= 31) { // 31 - 7 (1 week remaining)
        updateColour = "amber";
      } else {
        updateColour = "red";
      }
      break;
    case "quarterly":
      difference = differenceInCalendarDays(new Date(), latestUpdatedDate);
      if (difference < 86) {
        updateColour = "green";
      } else if (difference >= 86 && difference <= 93) { // 93 - 7 (1 week remaining)
        updateColour = "amber";
      } else {
        updateColour = "red";
      }
      break;
    default: updateColour = "gray";
  }
  return updateColour === "green" ? (
    <div className={`circle bg-green-500`} data-update={"green"} title={`Updates not required - ${getLatestUpdateDate(notes)}`} />
  ) : updateColour === "amber" ? (
    <div className={`circle bg-amber-500`} data-update={"amber"} title={`Updates due soon - ${getLatestUpdateDate(notes)}`} />
  ) : updateColour === "red" ? (
    <div className={`circle bg-red-500`} data-update={"red"} title={`Updates overdue - ${getLatestUpdateDate(notes)}`} />
  ) : updateColour === "gray" ? (
    <div className={`circle bg-gray-400`} data-update={"gray"} title={`No Updates`} />
  ) : <div className={`circle bg-gray-400`} data-update={"gray"} title={`No Updates`} />;
};

export const getColorBasedOnDate = dateStr => {
  const inputDate = new Date(dateStr);
  const currentDate = new Date();
  const monthsDifference = (currentDate.getFullYear() - inputDate.getFullYear()) * 12 + (currentDate.getMonth() - inputDate.getMonth());
  let color = '';
  // Determine the color based on the difference in months
  if (monthsDifference <= 6) {
    color = 'green';
  } else if (monthsDifference > 6 && monthsDifference <= 12) {
    color = 'orange';
  } else {
    color = 'red';
  }
  return color;
}

export function convertType(value) {
  if (isNumber(value)) {
    return value.toString();
  }

  if (isBoolean(value)) {
    return value ? "1" : "-1";
  }

  return value;
}

export function isNil(value) {
  return typeof value === "undefined" || value === null;
}

const orderByObject = (object, name) => {
  switch (name) {
    case "client":
      return object.Client.props.children[0];
    case "domain":
      return object.DomainUrl.props.children[0];
    case "server":
      return object.Server.props.children[0];
    case "updateRequired":
      return object.IsUpdateRequired.props.children[0];
  }
  return 1;
};

/**
 * @param {*[]} rows
 * @param {{orderBy: string, order: string}} sort
 */
export function sortRows(rows, sort) {
  let aLocale;
  let bLocale;
  try {
    return rows.sort((a, b) => {
      const { order, orderBy } = sort;
      if (!isObject(a[orderBy])) {
        if (isNil(a[orderBy])) return 1;
        if (isNil(b[orderBy])) return -1;
        aLocale = convertType(a[orderBy]);
        bLocale = convertType(b[orderBy]);
      } else {
        aLocale = convertType(orderByObject(a, orderBy));
        bLocale = convertType(orderByObject(b, orderBy));
      }

      if (order === "asc") {
        return aLocale.localeCompare(bLocale, "en", { numeric: isNumber(b[orderBy]) });
      } else {
        return bLocale.localeCompare(aLocale, "en", { numeric: isNumber(a[orderBy]) });
      }
    });
  } catch (err) {
    throw new Error("Failed to sort data", { cause: err });
  }
}

export const getFrameworkLink = (name, version) => {
  if (name === "WordPress") {
    return getWordpressDocsLink(version);
  } else if (name === "Laravel") {
    return getLaravelDocsLink(version);
  } else if (name === "Symfony") {
    return getSymfonyDocsLink(version);
  }
  return version;
};

export const getWordpressDocsLink = version => {
  if (!version) {
    return <a className="link" href={`https://wordpress.org/support/article/wordpress-versions/`} target="_blank"
              rel="noreferrer">-</a>;
  }
  return <a className="link"
            href={`https://wordpress.org/support/wordpress-version/version-${version.replace(".", "-")}`}
            target="_blank" rel="noreferrer">{version}</a>;
};

export const getLaravelDocsLink = version => {
  if (!version) {
    return <a className="link" href={`https://laravel.com/docs`} target="_blank" rel="noreferrer">-</a>;
  }
  return <a className="link" href={`https://laravel.com/docs/${version}/readme`} target="_blank"
            rel="noreferrer">{version}</a>;
};

export const getSymfonyDocsLink = version => {
  if (!version) {
    return <a className="link" href={`https://symfony.com/doc/current/index.html`} target="_blank"
              rel="noreferrer">-</a>;
  } else {
    return <a className="link" href={`https://symfony.com/doc/${version}/index.html`} target="_blank"
              rel="noreferrer">{version}</a>;
  }
};

export const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

export const checkDnsRecordsValid = async (domain, checkIsMandrill = true) => {
  const response = await fetch(`/api/dns-check?hostname=${domain}&checkIsMandrill=${checkIsMandrill}`);
  return await response.json();
}
