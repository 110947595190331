import { useQuery } from "react-query";
import { directusOptions } from "../../lib/api";
import { DIRECTUS_URL } from "../../lib/constants";

const fetchGlobalAlert = async () => {
  const response = await fetch(
    `${DIRECTUS_URL}/items/global_alert/`,
    directusOptions,
  );
  const global_alert = await response.json();
  return global_alert.data || [];
};

const useGlobalAlert = () => {
  return useQuery(["global_alert"], () => fetchGlobalAlert(), {
    refetchOnWindowFocus: false,
  });
};

export { useGlobalAlert, fetchGlobalAlert };
