import { useQuery } from "react-query";
import { directusOptions } from "../../lib/api";
import { DIRECTUS_URL } from "../../lib/constants";
import { convertMinsToMs } from "../../lib/utils";

const fetchDomains = async (filter) => {
  const fieldsToFetch = {
    fields: "id,slug,Client.*,DomainResource.id,DomainResource.stageUrl,DomainUrl,supportFrequency,generalNote,Notes.*,kumaId,language,languageVersion,frameworkOrPlatformVersion,prodWeb.*,prodDB.*,stageWeb.*,stageDB.*,TechStack.languageVersion,FrameworkOrPlatform.name,pipelines,violinist,isMandrill,hasMandrillAuth,requiresBackups,databaseBackupLastChecked,mediaBackupLastChecked",
  };
  let search = {
    "query": {
      "Client.name": {
        "_contains": filter,
      },
    },
  };
  const response = await fetch(
    `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&${new URLSearchParams(search).toString()}&sort=Client.name`,
    directusOptions,
  );
  const domains = await response.json();
  return domains.data || [];
};

const fetchUpdateNotesForDomain = async (filter, accessToken) => {
  const fieldsToFetch = {
    fields: "*",
  };
  const filters = `filter[_and][0][domain][id][_eq]=${filter}`;
  const response = await fetch(
    `${DIRECTUS_URL}/items/Notes/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  const updateNotes = await response.json();
  return updateNotes.data || [];
};

const fetchDomainsForServer = async (serverId, type) => {
  const fieldsToFetch = {
    fields: "id,Client.*,DomainUrl,supportFrequency,generalNote,language,languageVersion,frameworkOrPlatformVersion,TechStack.languageVersion,FrameworkOrPlatform.name,prodWeb.*,stageWeb.*,cost.*",
  };
  let typeFilter = ``;
  if (type === "Web") {
    typeFilter = `Web`;
  } else if (type === "DB") {
    typeFilter = `DB`;
  }
  const filters = `filter[_and][0][prod${typeFilter}][id][_eq]=${serverId}&filter[_and][2][stage${typeFilter}][id][_eq]=${serverId}`;
  const response = await fetch(
    `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}&meta[]=filter_count&sort=Client.name`,
    directusOptions,
  );
  const domains = await response.json();
  return {
    domains: domains.data,
    count: domains.meta.filter_count,
  } || [];
};

const fetchCostDomainsForServer = async (serverId, type) => {
  const fieldsToFetch = { fields: "cost.*" };
  let typeFilter = `Web`;
  let filters = ``;
  if (type === "Web") {
    typeFilter = `Web`;
  } else if (type === "DB") {
    typeFilter = `DB`;
  }
  if (typeFilter) {
    filters = `filter[_and][0][prod${typeFilter}][id][_eq]=${serverId}&filter[_and][2][stage${typeFilter}][id][_eq]=${serverId}&meta[]=filter_count`;
  }
  if (filters) {
    const response = await fetch(
      `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}`,
      directusOptions,
    );
    const domains = await response.json();
    return {
      domains: domains.data,
      count: domains.meta.filter_count,
    } || [];
  }
  return [];
};

const fetchDomainBySlug = async slug => {
  const filters = `filter[slug][_eq]=${slug}`;
  const response = await fetch(`${DIRECTUS_URL}/items/Domains/?${filters}`, directusOptions);
  const domain = await response.json();
  return {
    ...domain,
  };
};

const useDomains = filter => {
  return useQuery(["domains"], () => fetchDomains(filter), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(0),
  });
};

const useDomain = slug => {
  return useQuery(["domain", slug], () => fetchDomainBySlug(), {
    refetchOnWindowFocus: false,
  });
};

const useUpdateNotesForDomain = (domain, accessToken) => {
  return useQuery(["updateNotes", domain], () => fetchUpdateNotesForDomain(domain, accessToken), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(0),
  });
};

const useCostDomainsForServer = (serverId, type) => {
  return useQuery(["domainsCost", serverId], () => fetchCostDomainsForServer(serverId, type), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(10),
  });
};

const useDomainsForServer = (serverId, type) => {
  return useQuery({
    queryKey: ["domains", serverId],
    queryFn: () => fetchDomainsForServer(serverId, type),
    enabled: !!serverId,
    refetchOnWindowFocus: false,
  });
};

export {
  useDomains,
  fetchDomainBySlug,
  fetchDomains,
  fetchDomainsForServer,
  useDomainsForServer,
  useCostDomainsForServer,
  fetchCostDomainsForServer,
  useUpdateNotesForDomain,
};
