import { DIRECTUS_ACCESS_TOKEN, DIRECTUS_URL, URL } from "./constants";
// import { createDirectus, authentication, rest, login } from '@directus/sdk';
import { convertMinsToMs } from "./utils";

const directusConfig = {
  auth: {
    mode: "json", // 'json' in Node.js
    autoRefresh: true,
    msRefreshBeforeExpires: convertMinsToMs(60),
  },
  transport: {
    headers: {
      accept: "application/json",
    },
  },
};
// export const directus = new Directus(DIRECTUS_URL, directusConfig);
// export const directus = createDirectus(DIRECTUS_URL).with(authentication('json'));

// export const notesDirectus = directus.items("Notes");
// export const domainsDirectus = directus.items("Domains");

export const directusOptions = {
  method: "GET",
  headers: {
    accept: "application/json",
    Authorization: `Bearer ${DIRECTUS_ACCESS_TOKEN}`,
  },
};

export const refreshDirectusAccessToken = async refreshToken => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    "mode": "json",
    "refresh_token": refreshToken
  });

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  return await fetch(`${DIRECTUS_URL}/auth/refresh`, requestOptions)
};

export const loginDirectus = async (email, password) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    "email": email,
    "password": password
  });

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw
  };

  return await fetch("https://directus.ftd.io/auth/login", requestOptions);
};

export const getDirectusUser = async accessToken => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${accessToken}`)
  return await fetch(DIRECTUS_URL + "/users/me", {
    method: 'GET',
    headers: myHeaders
  })
}

export const createNote = async (newNote, accessToken, plugin = false) => {
  let url = `${DIRECTUS_URL}/items/Notes`;
  if (plugin) {
    url = `${DIRECTUS_URL}/items/plugin_notes`;
  }
  return await fetch(url, {
    "headers": {
      "accept": "application/json, text/plain, */*",
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    "body": JSON.stringify(newNote),
    "method": "POST",
  });
};

export const deleteNote = async (noteID, accessToken, plugin = false) => {
  let url = `${DIRECTUS_URL}/items/Notes/${noteID}`
  if (plugin) {
    url = `${DIRECTUS_URL}/items/plugin_notes/${noteID}`
  }
  return await fetch(url, {
    "headers": {
      Authorization: `Bearer ${accessToken}`,
    },
    "method": "DELETE",
  });
};

export const updateDomain = async (domainId, formBody, accessToken) => {
  return await fetch(`${DIRECTUS_URL}/items/Domains/${domainId}`, {
    method: "PATCH",
    "headers": {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formBody)
  })
}

export const fetchDomain = async id => {
  const fieldsToFetch = {
    fields: "*.*",
  };
  return await fetch(
    `${DIRECTUS_URL}/items/Domains/${id}?${new URLSearchParams(fieldsToFetch).toString()}&sort=id`,
    directusOptions,
  );
};

export const fetchUser = async id => {
  return await fetch(
    `${DIRECTUS_URL}/users/${id}`,
    directusOptions,
  );
};

export const getSslDetails = async hostname => {
  return await fetch(`${URL}/api/ssl-check/?hostname=${hostname}`, {
    next: { revalidate: 300 }
  });
};

export const getDomains = async (filter) => {
  const fieldsToFetch = {
    fields: "id,Client.*,DomainResource.id,DomainUrl,supportFrequency,generalNote,Notes.*,kumaId,language,languageVersion,frameworkOrPlatformVersion,prodWeb.*,prodDB.*,stageWeb.*,stageDB.*,TechStack.languageVersion,FrameworkOrPlatform.name,pipelines,violinist",
  };
  // attempt to cache for 5 mins
  directusOptions.next = {
    revalidate: 300,
  };
  const response = await fetch(
    `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&sort=-SupportFrequency`,
    directusOptions,
  );
  const domains = await response.json();
  return domains.data || [];
};

export const getDomainsForServer = async (serverId, type) => {
  const fieldsToFetch = {
    fields: "id,Client.*,DomainUrl,supportFrequency,generalNote,language,languageVersion,frameworkOrPlatformVersion,TechStack.languageVersion,FrameworkOrPlatform.name,prodWeb.*,stageWeb.*,cost.*",
  };
  let typeFilter = ``;
  if (type === "Web") {
    typeFilter = `Web`;
  } else if (type === "DB") {
    typeFilter = `DB`;
  }
  const filters = `filter[_and][0][prod${typeFilter}][id][_eq]=${serverId}&filter[_and][2][stage${typeFilter}][id][_eq]=${serverId}`;
  const response = await fetch(
    `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}&meta[]=filter_count&sort=Client.name`,
    directusOptions,
  );
  const domains = await response.json();
  return {
    domains: domains.data,
    count: domains.meta?.filter_count,
  } || [];
};

export const getCostDomainsForServer = async (serverId, type) => {
  const fieldsToFetch = { fields: "cost.*" };
  let typeFilter = `Web`;
  let filters = ``;
  if (type === "Web") {
    typeFilter = `Web`;
  } else if (type === "DB") {
    typeFilter = `DB`;
  }
  if (typeFilter) {
    filters = `filter[_and][0][prod${typeFilter}][id][_eq]=${serverId}&filter[_and][2][stage${typeFilter}][id][_eq]=${serverId}&meta[]=filter_count`;
  }
  if (filters) {
    const response = await fetch(
      `${DIRECTUS_URL}/items/Domains/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}`,
      directusOptions,
    );
    const domains = await response.json();
    return {
      domains: domains.data,
      count: domains.meta.filter_count,
    } || [];
  }
  return [];
};

export const getServers = async () => {
  const fieldsToFetch = {
    fields: "*.*",
  };
  directusOptions.next = {
    revalidate: 300,
  };
  const response = await fetch(
    `${DIRECTUS_URL}/items/Servers/?${new URLSearchParams(fieldsToFetch).toString()}&filter[_and][0][isInternal][_eq]=true&sort=weight,-lastMonthlyCost,name`,
    directusOptions,
  );
  const servers = await response.json();
  return servers.data || [];
};

