import { DIRECTUS_URL } from "../../lib/constants";
import { directusOptions } from "../../lib/api";
import { useQuery } from "react-query";
import { convertMinsToMs } from "../../lib/utils";

const fetchPlugins = async (filter) => {
  const fieldsToFetch = {
    fields: "*.*",
  };
  const response = await fetch(
    `${DIRECTUS_URL}/items/plugins/?${new URLSearchParams(fieldsToFetch).toString()}&sort=name`,
    directusOptions,
  );
  const plugins = await response.json();
  return plugins.data || [];
};

const fetchUpdateNotesForPlugin = async (filter, accessToken) => {
  const fieldsToFetch = {
    fields: "*",
  };
  const filters = `filter[_and][0][plugin][id][_eq]=${filter}`;
  if (filter) {
    const response = await fetch(
      `${DIRECTUS_URL}/items/plugin_notes/?${new URLSearchParams(fieldsToFetch).toString()}&${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const updateNotes = await response.json();
    return updateNotes.data || [];
  }
  return [];
};

const useUpdateNotesForPlugin = (plugin, accessToken) => {
  return useQuery(["updateNotes", plugin], () => fetchUpdateNotesForPlugin(plugin, accessToken), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(0),
  });
};

const usePlugins = () => {
  return useQuery("plugins", () => fetchPlugins(), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(10),
  });
};

export { fetchPlugins, usePlugins, useUpdateNotesForPlugin };
