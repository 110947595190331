import { useQuery } from "react-query";
import { DIRECTUS_URL } from "../../lib/constants";
import { directusOptions } from "../../lib/api";
import { convertMinsToMs } from "../../lib/utils";

const fetchServers = async () => {
  const fieldsToFetch = {
    fields: "*.*"
  };
  const response = await fetch(
    `${DIRECTUS_URL}/items/Servers/?${new URLSearchParams(fieldsToFetch).toString()}&filter[_and][0][isInternal][_eq]=true&sort=weight,-lastMonthlyCost,name`,
    directusOptions,
  );
  const servers = await response.json();
  return servers.data || [];
};

const useServers = () => {
  return useQuery(["servers"], () => fetchServers(), {
    refetchOnWindowFocus: false,
    cacheTime: convertMinsToMs(10)
  });
};

export { useServers, fetchServers };
